.Container-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.box-layout {
}
.label-box {
  margin-left: 5px;
  width: 70px;
}
