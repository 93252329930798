@font-face {
  font-family: "Pretendard-Light";
  src: url("../../assets/fonts/Pretendard-Light.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
body {
  -ms-overflow-style: none;
}

/* ::-webkit-scrollbar {
  display: none;
} */

/* .login-body {
  background-color: #2a3042;
} */

.login-box {
  position: absolute;
  top: calc(50% - 390px);
  left: calc(50% - 559px);
  width: 1118px;
  height: 700px;
}

.login-box .logo {
  text-align: center;
  line-height: 0px;
}

.login-box .logo img {
  width: 250px;
  height: 100%;
}

.login-box .title {
  font-family: Pretendard-Light;
  font-size: 52px;
  color: white;
  text-align: center;
  padding-top: 30px;
  /* padding-bottom: 30px; */
}
.login-box .sub-title {
  font-family: Pretendard-Light;
  font-size: 25px;
  color: white;
  text-align: center;
  /* padding-top: 5px; */
  padding-bottom: 50px;
}
.login-box .social-icons {
  text-align: center;
  background-image: url("../../assets/images/login/icon-bar.png");
  background-repeat: no-repeat;
  width: 314px;
  height: 45px;
  margin: auto;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-around;
}

.login-box .social-icons div {
  padding: 5px 0px 0px 0px;
}

.login-box .social-icons div > img {
  width: 35px;
  height: 35px;
  cursor: pointer;
  /* padding: 5px 10px 0px 0px; */
}
.login-box .social-icons div > a > img {
  width: 35px;
  height: 35px;
  /* padding: 5px 10px 0px 0px; */
  border-radius: 70%;
}
.login-box .login-message {
  font-family: Pretendard-Light;
  font-size: 12px;
  color: #aaaaaa;
  text-align: center;
  padding-bottom: 80px;
  padding-top: 10px;
  line-height: 1.5em;
}
.login-box .landing {
  text-align: center;
}
.login-box .landing img {
  width: 500px;
  height: 100%;
  padding-bottom: 20px;
}

/* font-family: Pretendard-Light;
font-size: 12px;
color: #aaaaaa;
text-align: center;
padding-bottom: 60px; */
.copyright {
  position: absolute;
  right: 20px;
  top: calc(100% - 30px);
  font-family: Pretendard-Light;
  font-size: 12px;
  color: #aaaaaa;
}
