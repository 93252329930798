.required {
  color: red;
  float: right;
}

.curserStyle {
  cursor: pointer;
  text-decoration: underline dashed;
  text-underline-position: under;
}
